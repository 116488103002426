<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0" style="overflow: hidden">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <!-- <h2 v-if="!logoFront" class="brand-text text-primary ml-1">
          Whitelabels
        </h2>
        <b-img
          v-else
          fluid
          :src="logoFront"
          alt="Login V2"
          width="150"
        /> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class=" d-none d-lg-flex align-items-center m-0 p-0"
      >
      <b-img
          fluid
          :src="$store.state.user.urlWallpaper"
          alt="Login V2"
          class="imgLogin"
          width="100%"
        />
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <div style="position: absolute; top: 10px; right: 20px;"><locale/></div>
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div v-if="$store.state.user.urlLogo" class="d-flex justify-content-center">
            <b-img
              fluid
              :src="$store.state.user.urlLogo"
              alt=""
              width="150"
            />
          </div>
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
           {{$t('messages.welcomeswhitelabel')}}
          </b-card-title>
            
          <!-- <b-card-text class="mb-2">
            {{$t('messages.pleasesigin')}}
          </b-card-text> -->

          <!-- form -->
          <validation-observer
            ref="loginForm"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{$t('labels.username')}}</label>

                </div>
                
                <validation-provider
                  #default="{ errors }"
                  name="User name"
                  vid="userName"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="form.userName"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{$t('labels.password')}}</label>

                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="form.password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{$t('labels.rememberme')}}
                </b-form-checkbox>
              </b-form-group>
              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="disabledBtn"
              >
                {{$t('buttons.sigin')}}
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, 
  BCol, 
  BLink, 
  BFormGroup, 
  BFormInput, 
  BInputGroupAppend, 
  BInputGroup, 
  BFormCheckbox, 
  BCardText, 
  BCardTitle, 
  BImg, 
  BForm, 
  BButton, 
  BAlert, 
  VBTooltip,
} from 'bootstrap-vue'
import axios from '@/libs/axios'
import { required } from '@validations'
import { ref, onUnmounted} from '@vue/composition-api'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import moduleStoreModule from '../modules/moduleStoreModule'
import roleStoreModule from '../roles/roleStoreModule'
import {connectSocket} from "@/libs/socket-io/socket";
import {loginErrorHandle} from "@/helpers/errorHandler";
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import {checkUser2FA} from "@/auth/utils";
//import Locale from '@core/components/Locale.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
    //Locale
  },
  mixins: [togglePasswordVisibility],
  data(){
    return {
      form:{
        userName: '',
        password: '',
      },
      sideImg: require('@/assets/images/login/login-w.png'),
      required,
      status: false,
      disabledBtn: false,
    }
  },
  computed: {
    logoFront(){
      return localStorage.getItem('logoFront')
    },
    wallpaperUrl(){
      return localStorage.getItem('wallpaperUrl')
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (this.$store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg
    },
  },
  methods:{
    async login () {
      await this.validateForm()
      try{
        const { data } = await store.dispatch('user/login', this.form)
        console.log(data)
        if (checkUser2FA(data.user)){
            store.commit('verification/SET_COMPONENT_TYPE', data.user.type2FA)
            store.commit('verification/SET_VERIFICATION_MODAL', true)
        } else {
            //socket-io connect to server
            connectSocket()
            this.$router.push({name:'dashboard'})
        }
      } catch(err){
        console.log(err)
        const message = await loginErrorHandle(err)
        this.disabledBtn = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    
    async validateForm(){
      try{
        this.disabledBtn = true
        const success = await this.$refs.loginForm.validate()
      } catch(err){
        console.log(err)
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('login.errorValidate'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    // This funtion prepare role user logged data to send it to sessionstorage
    async getRoles(){
      const ROLE_APP_STORE_MODULE_NAME = 'app-role'
      const MODULE_APP_STORE_MODULE_NAME = 'app-module'
      if (!store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
      if (!store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.registerModule(MODULE_APP_STORE_MODULE_NAME, moduleStoreModule)
      onUnmounted(() => {
        if (store.hasModule(ROLE_APP_STORE_MODULE_NAME)) store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
        if (store.hasModule(MODULE_APP_STORE_MODULE_NAME)) store.unregisterModule(MODULE_APP_STORE_MODULE_NAME)
      })

      var roles = []
      await store.dispatch('app-role/fetchRoles')
        .then(response => { 
          roles = response.data.roles})
    
      var modules = []
      await store.dispatch('app-module/fetchModules')
        .then(response => { 
          modules = response.data.modules}) 
      
      try{
        const userLogged = JSON.parse(localStorage.getItem('userData'))
        var permissions = []
        userLogged.rolesAllowed.forEach(roleAllowed => {
          var singleRole = roles.find(role => roleAllowed == role._id)
          if (singleRole) {
            singleRole.modules.forEach(moduleAllowed => {
              var singleModule = modules.find(module => moduleAllowed.module == module._id)
              if (singleModule) {
                var permission = {
                  path: '',
                  create: '',
                  update: '',
                  view: '',
                  delete: ''
                }
                permission.path = singleModule.slug
                permission.create = moduleAllowed.create
                permission.update = moduleAllowed.update
                permission.view = moduleAllowed.view
                permission.delete = moduleAllowed.delete
                permissions.push(permission)
              }
            })
          }
          localStorage.setItem('permissions', JSON.stringify(permissions))
        })  
      } catch(err){
        console.log(err)
      }
     
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.imgLogin {
  width: 100%;
  height: 100vh;
  object-fit: cover;

  
  
}
</style>
